import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';
import { Component } from '@angular/core';

@Component({
  selector: 'app-logout',
  template: "",
  styles: "",
})
export class LogoutComponent {

  constructor(
    private router: Router,
    private loginService: AuthService
  ) {
    this.loginService.signOutUser().then(() => {
      this.router.navigate(['/login']);
    })
  }
}
